import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/Arrow-down.svg";
import { Form, Button } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/Fill 1.svg";
import { Gear, X } from "react-bootstrap-icons";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  InputAdornment,
  Grid,
  Button as MuiButton,
} from "@mui/material";
import Pagination from "../../PaginationComp/Paginations";
import DatabaseApi from "../../../../services/database_api";
import axios from "axios";

interface PlanData {
  rate_per_km: string;
  rate_per_hill_km: string;
  accident_recovery_rate: string;
  miscellaneous_rate: string;
}
const defaultContactDetails: PlanData = {
  rate_per_km: "",
  rate_per_hill_km: "",
  accident_recovery_rate: "",
  miscellaneous_rate: "",
};

const tickets = [
  {
    id: "1",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-12-2024",
    chargeFromUser: "$500",
  },
  {
    id: "2",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-12-2024",
    chargeFromUser: "$500",
  },
  {
    id: "3",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
    chargeFromUser: "$500",
  },
  {
    id: "4",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
    chargeFromUser: "$500",
  },
  {
    id: "5",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
    chargeFromUser: "$500",
  },
  {
    id: "6",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
    chargeFromUser: "$500",
  },
  {
    id: "7",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
    chargeFromUser: "$500",
  },
  {
    id: "8",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
    chargeFromUser: "$500",
  },
  {
    id: "9",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
    chargeFromUser: "$500",
  },
  {
    id: "10",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
    chargeFromUser: "$500",
  },
  {
    id: "11",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
    chargeFromUser: "$500",
  },
  {
    id: "12",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
    chargeFromUser: "$500",
  },
  {
    id: "13",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
    chargeFromUser: "$500",
  },
  {
    id: "14",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-08-2024",
    chargeFromUser: "$500",
  },
  {
    id: "15",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-10-2024",
    chargeFromUser: "$500",
  },
  {
    id: "16",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-11-2024",
    chargeFromUser: "$500",
  },
  {
    id: "17",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-01-2024",
    chargeFromUser: "$500",
  },
  {
    id: "18",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-04-2024",
    chargeFromUser: "$500",
  },
  {
    id: "19",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-05-2024",
    chargeFromUser: "$500",
  },
  {
    id: "20",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-07-2024",
    chargeFromUser: "$500",
  },
  {
    id: "21",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-09-2024",
    chargeFromUser: "$500",
  },
  {
    id: "22",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-10-2024",
    chargeFromUser: "$500",
  },
  {
    id: "23",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-11-2024",
    chargeFromUser: "$500",
  },
];

const SupportTicket: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [PlanData, SetPlanData] = useState<PlanData>(defaultContactDetails);
  const [initialValues, setInitialValues] = useState<PlanData>(
    defaultContactDetails
  );
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [activationStatus, setActivationStatus] = useState("Paid");
  const [openModal, setOpenModal] = useState(false);

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [showError, setShowError] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<{
    name: string;
    pendingAmount: string;
    chargeFromUser: string;
  } | null>(null);

  const fetchPlanData = async () => {
    const apiUrl = DatabaseApi.getPayoutPlan();
    const token = localStorage.getItem("authToken");

    try {
      setLoading(true);
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.data) {
        const fetchedData = response.data.data;

        const sanitizedData = {
          rate_per_km: fetchedData.rate_per_km || "",
          rate_per_hill_km: fetchedData.rate_per_hill_km || "",
          accident_recovery_rate: fetchedData.accident_recovery_rate || "",
          miscellaneous_rate: fetchedData.miscellaneous_rate || "",
        };
        SetPlanData(sanitizedData);
        setInitialValues(sanitizedData);
      } else {
        setError("No data available.");
      }
    } catch (error) {
      setError("Failed to fetch contact details. Please try again later.");
      console.error("Error fetching contact details:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPlanData();
  }, []);
  // row click model handel
  const handleRowClick = (driver: {
    id: string;
    name: string;
    pendingAmount: string;
    amountPaid: string;
    dateOfPayment: string;
    chargeFromUser: string;
  }) => {
    if (activationStatus === "Pending") {
      setSelectedDriver(driver);
    } else if (activationStatus === "Paid") {
      navigate(`/admin/driver-payouts/${driver.id}`, {
        state: {
          name: driver.name,
          amountPaid: driver.amountPaid,
          dateOfPayment: driver.dateOfPayment,
        },
      });
    }
  };
  const handleCloseDriverModal = () => {
    setSelectedDriver(null);
  };

  const handleMarkAsPaid = () => {
    console.log(`Marked as paid for ${selectedDriver?.name}`);
    handleCloseDriverModal();
  };
  //

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const validateField = (name: string, value: string) => {
    if (
      [
        "ratePerKM",
        "rateHillyKm",
        "accidentRate",
        "miscellaneousRate",
      ].includes(name)
    ) {
      if (isNaN(parseFloat(value)) || parseFloat(value) <= 0) {
        return `${name} must be a positive number`;
      }
    }
    return "";
  };

  // model inbox value handel
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    SetPlanData((prev) => ({ ...prev, [name]: value }));

    const error = validateField(name, value);
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const isFormValid = () => {
    const requiredFields = [
      "ratePerKM",
      "rateHillyKm",
      "accidentRate",
      "miscellaneousRate",
    ];

    return requiredFields.every(
      (field) => PlanData[field as keyof typeof PlanData] && !errors[field]
    );
  };

  //handel submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShowError(false);

    if (isFormValid()) {
      // Process the form data
      console.log("Form submitted:", PlanData);
      // Here you would typically send the data to your backend
      // After successful submission, close the modal
      handleCloseModal();
    } else {
      setShowError(true);
    }
  };

  const handleIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // Prevent event from propagating to the Select component
    setIsOpen(!isOpen);
  };

  // Filter drivers based on search query
  const filteredDrivers = tickets.filter((tickets) =>
    tickets.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  //Pagination
  const [itemsPerPage] = useState(10); // Or any other number you prefer
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDrivers.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{ maxWidth: "90%", marginLeft: "240px", marginTop: "40px" }}
    >
      <Box
        sx={{ p: 3.5, bgcolor: "background.paper", fontFamily: "Montserrat" }}
        color="#2C3E50"
      >
        <div className="flex justify-between items-center mb-4">
          <Typography
            variant="h5"
            gutterBottom
            color="#2C3E50"
            style={{ fontFamily: "Montserrat", fontWeight: "600" }}
          >
            Driver Payout
          </Typography>
          <div className="flex items-center gap-4">
            <Select
              open={isOpen}
              onClose={() => setIsOpen(false)}
              onOpen={() => setIsOpen(true)}
              value={activationStatus}
              onChange={(e) => setActivationStatus(e.target.value as string)}
              IconComponent={() => (
                <div
                  onClick={handleIconClick}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >

                
                <ArrowIcon
                  style={{
                    width: "2rem",
                    height: "2rem",
                    marginRight: "8px",
                    fontFamily: "Montserrat",
                    color: "#FDA214",
                      transition: "transform 0.3s ease",
                      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
                </div>
              )}
              sx={{
                width: 200,
                height: "3rem",
                borderRadius: "10px",
                fontFamily: "Montserrat",
                "& fieldset": {
                  borderRadius: "10px",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214", // Custom border color on focus
                  boxShadow: "0 0 0 2px #FDA214", // Custom box shadow on focus
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214", // Optional: set border color on hover
                },
              }}
            >
              <MenuItem value="Paid" style={{ fontFamily: "Montserrat" }}>
                Paid
              </MenuItem>
              <MenuItem value="Pending" style={{ fontFamily: "Montserrat" }}>
                Pending
              </MenuItem>
            </Select>

            <div className="relative flex items-center">
              <SearchIcon
                className="absolute left-4 top-1/2 transform -translate-y-1/2"
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#FDA214",
                  fontFamily: "Montserrat",
                }} // Adjust size and color if necessary
              />
              <Form.Control
                type="text"
                placeholder="Search Drivers by Name"
                className="pl-10 pr-6 py-2 rounded-full border border-gray-300"
                value={searchQuery}
                onChange={handleSearch}
                style={{
                  width: "20rem",
                  height: "3rem",
                  fontFamily: "Montserrat",
                  lineHeight: "3rem",
                  borderRadius: "20px",
                  textAlign: "left",
                  paddingLeft: "40px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
              <style>
                {`
                 .relative input::placeholder {
                 color: #B4BDC4; // Placeholder color
                  }
                `}
              </style>
              <Button
                variant="warning"
                className="ml-2 flex items-center justify-center"
                style={{
                  backgroundColor: "#FDA214",
                  color: "#fff",
                  fontFamily: "Montserrat",
                  borderRadius: "10px",
                  height: "3rem",
                  width: "10rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                }}
                onClick={handleOpenModal}
              >
                <Gear size={18} />
                <span style={{ fontFamily: "Montserrat" }}>Payout Rates</span>
              </Button>
            </div>
          </div>
        </div>

        <TableContainer
          component={Paper}
          elevation={0}
          style={{ fontFamily: "Montserrat" }}
        >
          <Table>
            <TableHead
              sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Driver Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Completed Trips
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Total Distance (km)
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Accident Recovery
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Charge from User
                </TableCell>
                {activationStatus === "Paid" ? (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      padding: "16px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Amount Paid
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      padding: "16px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Pending Amount
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems.map((ticket) => (
                <TableRow
                  key={ticket.id}
                  onClick={() =>
                    handleRowClick({
                      id: ticket.id,
                      name: ticket.name,
                      pendingAmount: ticket.pendingAmount,
                      amountPaid: ticket.amountPaid,
                      dateOfPayment: ticket.dateOfPayment,
                      chargeFromUser: ticket.chargeFromUser,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                    fontFamily: "Montserrat",
                  }}
                >
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.name}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.completedTrips}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.totalDistance}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.accidentRecovery}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.chargeFromUser}
                  </TableCell>
                  {activationStatus === "Paid" ? (
                    <TableCell style={{ fontFamily: "Montserrat" }}>
                      {ticket.amountPaid}
                    </TableCell>
                  ) : (
                    <TableCell style={{ fontFamily: "Montserrat" }}>
                      {ticket.pendingAmount}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="d-flex justify-content-center align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px", fontFamily: "Montserrat" }}
        >
          {/* <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(filteredDrivers.length / itemsPerPage)}
            onPageChange={handlePageChange}
          /> */}

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            fontFamily: "Montserrat",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350, // Adjust as per requirement
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 5,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              fontFamily: "Montserrat",
              top: "70%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 3, fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Payout Rates
            </Typography>

            <MuiButton
              onClick={handleCloseModal}
              sx={{
                minWidth: "auto",
                fontFamily: "Montserrat",
                p: 0,
                color: "#FDA214",
                fontWeight: "bold",
                mt: -3,
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>

          {showError && !isFormValid() && (
            <Alert severity="error" style={{ fontFamily: "Montserrat" }}>
              <AlertTitle style={{ fontFamily: "Montserrat" }}>
                Error
              </AlertTitle>
              Please fill in all required fields correctly before submitting.
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-6 p-6">
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  label="Rate per Km"
                  name="ratePerKM"
                  value={PlanData.rate_per_km}
                  onChange={handleChange}
                  error={!!errors.ratePerKM}
                  helperText={errors.ratePerExtraKM}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                  sx={{
                    maxWidth: "230px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px",
                      fontSize: "0.875rem",
                      padding: "0 12px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": { borderColor: "#FDA214" },
                    },
                    "& .MuiInputLabel-root.Mui-focused": { color: "#FDA214" },
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Rate per Hilly Km"
                  name="rateHillyKm"
                  value={PlanData.rate_per_hill_km}
                  onChange={handleChange}
                  error={!!errors.rateHillyKm}
                  helperText={errors.rateHillyKm}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                  sx={{
                    maxWidth: "230px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px",
                      fontSize: "0.875rem",
                      padding: "0 12px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": { borderColor: "#FDA214" },
                    },
                    "& .MuiInputLabel-root.Mui-focused": { color: "#FDA214" },
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Accident Recovery Rate"
                  name="accidentRate"
                  value={PlanData.accident_recovery_rate}
                  onChange={handleChange}
                  error={!!errors.accidentRate}
                  helperText={errors.accidentRate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                  sx={{
                    maxWidth: "230px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px",
                      fontSize: "0.875rem",
                      padding: "0 12px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": { borderColor: "#FDA214" },
                    },
                    "& .MuiInputLabel-root.Mui-focused": { color: "#FDA214" },
                  }}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
                gap: 8,
                fontFamily: "Montserrat",
              }}
            >
              <MuiButton
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#FDA214",
                  fontFamily: "Montserrat",
                  color: "white",
                  "&:hover": {
                    bgcolor: "#FB923C",
                  },
                  width: "150px",
                }}
                disabled={!isFormValid()}
              >
                Save Changes
              </MuiButton>
            </Box>
          </form>
        </Box>
      </Modal>
      <Modal
        open={!!selectedDriver && activationStatus === "Pending"}
        onClose={handleCloseDriverModal}
        aria-labelledby="driver-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 650,
            bgcolor: "background.paper",
            p: 5,
            borderRadius: 4,
            fontFamily: "Montserrat",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#2C3E50",
              }}
            >
              Pending Amount
            </Typography>
            <MuiButton
              onClick={handleCloseDriverModal}
              sx={{
                minWidth: "auto",
                p: 0,
                color: "#FDA214",
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 4,
              mb: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  mb: 0,
                  color: "#2C3E50",
                  fontSize: "1.1rem",
                }}
              >
                Amount to be paid
              </Typography>
              <TextField
                value={` ${selectedDriver?.pendingAmount || 6000}`}
                disabled
                fullWidth
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#2C3E50",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                    height: "15px",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>

            <Box>
              <Typography
                sx={{
                  mb: 0,
                  color: "#2C3E50",
                  fontSize: "1.1rem",
                }}
              >
                Charge from User
              </Typography>
              <TextField
                value={` ${selectedDriver?.chargeFromUser || 500}`}
                disabled
                fullWidth
                sx={{
                  borderRadius: "8px",
                  "& .MuiInputBase-input": {
                    color: "#2C3E50",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                    height: "15px",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MuiButton
              onClick={handleMarkAsPaid}
              variant="contained"
              sx={{
                bgcolor: "#FDA214",
                color: "white",
                px: 5,
                py: 1.5,
                mt: 3,
                borderRadius: "6px",
                fontSize: "1rem",
                "&:hover": {
                  bgcolor: "#FB923C",
                },
              }}
            >
              Mark as Paid
            </MuiButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SupportTicket;
