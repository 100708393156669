import React, { useState, useEffect } from "react";
import axios from "axios"; //  axios for API requests
import { ReactComponent as PersonDash } from "../../../assets/icons/flowbite_user-remove-outline.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/solar_phone-rounded-broken.svg";
import { ReactComponent as VehicleIcon } from "../../../assets/icons/ph_car-profile.svg";
import { ReactComponent as LicensePlateIcon } from "../../../assets/icons/vaadin_password.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/solar_letter-broken.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Fill 1.svg";
import { ReactComponent as ColorIcon } from "../../../assets/icons/fluent_color-24-regular.svg";
import { ReactComponent as StatusIcon } from "../../../assets/icons/material-symbols-light_paid-outline.svg";
import { ReactComponent as DollorIcon } from "../../../assets/icons/newDollor.svg";
import { ReactComponent as Defaulticons } from "../../../assets/icons/defaultImg.svg";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "../PaginationComp/Paginations";
import DatabaseApi from "../../../services/database_api";
import RippleButton from "../../../RippleButton/RippleButton";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/Arrow-down.svg";
import { ReactComponent as GreenIcon } from "../../../assets/icons/Greeen.svg";

// Interface for driver data from the API
interface Driver {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  created_at: string;
  vehicle_type?: string;
  vehicle_color?: string;
  license_plate_number?: string;
  account_status?: string;
  profile_image?: string | null;
  subscriptionStatus?: string;
  subscriptionStartDate?: string;
  plan_expiry_date?: string;
}

interface ApiResponse {
  data: any[];
  current_page: number;
  total_page: number;
  total_data: number;
}

const UserComponent: React.FC = () => {
  const location = useLocation();
  const [drivers, setDrivers] = useState<Driver[]>([]); // State to hold drivers data
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Driver | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [itemsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  // Debounced search
  const [debouncedSearch, setDebouncedSearch] = useState(searchQuery);
  const [isOpen, setIsOpen] = useState(false);
  const [activationStatus, setActivationStatus] = useState(() => {
    // Initialize with state from navigation or default to "Activate"
    return location.state?.returnToStatus || "Activate";
  });
  const StatusIcon = activationStatus === "Activate" ? PersonDash : GreenIcon;

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchQuery]);

  // Function to handle page changes
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // Fetch user data
  const fetchUser = async (
    type: string,
    page: number,
    query: string,
    status: string
  ) => {
    setLoading(true);
    setError(null);
    const token = localStorage.getItem("authToken");
    const apiUrl = DatabaseApi.getAllUserUrl(page, query, status, type);

    try {
      const response = await axios.get<ApiResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          account_status: status === "Activate" ? "Activate" : "Deactivate",
          type: type,
        },
      });

      const driverData = Array.isArray(response.data.data)
        ? response.data.data
        : [];

      // Optionally, you can add an additional filter here if needed
      const filteredDrivers = driverData.filter(
        (driver) =>
          driver.account_status ===
          (status === "Activate" ? "Activate" : "Deactivate")
      );
      setDrivers(filteredDrivers);
      setTotalPages(response.data.total_page); // Set total pages for pagination
      setTotalItems(response.data.total_data);
      setCurrentPage(response.data.current_page);
    } catch (error) {
      setError("Failed to fetch drivers data. Please try again later.");
      console.error("Error fetching drivers data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Effect to fetch drivers whenever page or searchQuery changes
  useEffect(() => {
    // Use a default type if no sort order is selected
    const sortType =
      sortOrder === "Earliest" ? "old" : sortOrder === "Latest" ? "new" : ""; // or your default type

    fetchUser(sortType, currentPage, debouncedSearch, activationStatus);
  }, [currentPage, debouncedSearch, activationStatus, sortOrder]);

  const renderAttachment = (attachment: string | null | undefined) => {
    // First, check if selectedUser exists (instead of driver)
    if (!selectedUser) {
      return <Defaulticons className="w-20 h-20 rounded-full" />;
    }

    // Then check the attachment
    if (!selectedUser.profile_image) {
      return <Defaulticons className="w-20 h-20 rounded-full" />;
    }

    const fullPath = DatabaseApi.getUserProfile(selectedUser.profile_image);

    if (fullPath.endsWith(".txt")) {
      return (
        <a href={fullPath} target="_blank" rel="noopener noreferrer">
          View Text File
        </a>
      );
    } else {
      return (
        <img
          src={fullPath}
          alt="Profile"
          className="w-20 h-20 rounded-full"
          style={{ maxWidth: "100px", maxHeight: "100px", objectFit: "cover" }}
        />
      );
    }
  };
  // handle Menu
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSortSelection = (order: string) => {
    setSortOrder(order);
    handleCloseMenu();

    // Call fetchUser with the new type parameter
    fetchUser(
      sortOrder === "Earliest" ? "old" : "new",
      currentPage,
      debouncedSearch,
      activationStatus
    );
  };

  const handleRejectModal = () => {
    setShowRemoveModal(true);
    handleCloseModal(); //
  };

  // Reject modal
  const handleDelete = async () => {
    if (!selectedUser) return;
    setIsDeleting(true);
    setDeleteError(null);

    const token = localStorage.getItem("authToken");

    try {
      await axios.delete(DatabaseApi.deleteUserUrl(), {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          id: selectedUser.id,
        },
      });
      // Close the modal and refresh the data
      setShowRemoveModal(false);
      setSelectedUser(null);

      // Refresh the users list
      fetchUser(
        sortOrder === "Earliest" ? "old" : "new",
        currentPage,
        debouncedSearch,
        activationStatus
      );
    } catch (error) {
      console.error("Error deleting user:", error);
      setDeleteError(
        // error.response?.data?.message ||
        "Failed to delete user. Please try again."
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteAndBlock = async () => {
    const token = localStorage.getItem("authToken");

    if (!selectedUser) {
      console.error("No user selected for blocking");
      setShowRemoveModal(false);
      return;
    }

    try {
      const response = await axios.put(
        DatabaseApi.updateAccountStatusUserUrl(),
        {
          user_id: selectedUser.id.toString(),
          account_status: "Deactivate",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Detailed logging
        console.log("Block response:", response.data);

        // Update local state
        const updatedDrivers = drivers.map((d) =>
          d.id === selectedUser.id ? { ...d, account_status: "Deactivate" } : d
        );

        setDrivers(updatedDrivers);

        // Refresh the current page of drivers
        fetchUser(
          sortOrder === "Earliest" ? "old" : "new",
          currentPage,
          debouncedSearch,
          activationStatus
        );

        // Close modals
        setShowRemoveModal(false);
        setSelectedUser(null);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error: any) {
      // More comprehensive error logging
      console.error("Full error object:", error);

      // Check for specific error types
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);

        // Use more specific error message from server if available
        const errorMessage =
          error.response.data?.message ||
          "Failed to block driver. Please try again.";

        setError(errorMessage);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
        setError("No response from server. Please check your connection.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", error.message);
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setShowRemoveModal(false);
    }
  };
  const handleUnBlock = async () => {
    const token = localStorage.getItem("authToken");

    if (!selectedUser) {
      console.error("No user selected for blocking");
      setShowRemoveModal(false);
      return;
    }

    try {
      const response = await axios.put(
        DatabaseApi.updateAccountStatusUserUrl(),
        {
          user_id: selectedUser.id.toString(),
          account_status: "Activate",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Detailed logging
        console.log("Block response:", response.data);

        // Update local state
        const updatedDrivers = drivers.map((d) =>
          d.id === selectedUser.id ? { ...d, account_status: "Activate" } : d
        );

        setDrivers(updatedDrivers);

        // Refresh the current page of drivers
        fetchUser(
          sortOrder === "Earliest" ? "old" : "new",
          currentPage,
          debouncedSearch,
          activationStatus
        );

        // Close modals
        setShowRemoveModal(false);
        setSelectedUser(null);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error: any) {
      // More comprehensive error logging
      console.error("Full error object:", error);

      // Check for specific error types
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);

        // Use more specific error message from server if available
        const errorMessage =
          error.response.data?.message ||
          "Failed to block driver. Please try again.";

        setError(errorMessage);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
        setError("No response from server. Please check your connection.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", error.message);
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setShowRemoveModal(false);
    }
  };

  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false);
  };

  //
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // Prevent event from propagating to the Select component
    setIsOpen(!isOpen);
  };

  //Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Use slice only if drivers is an array
  const currentItems = Array.isArray(drivers)
    ? drivers.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "90%",
        marginLeft: "240px",
        marginTop: "40px",
        fontFamily: "Montserrat",
      }}
    >
      <Box sx={{ p: 3 }}>
        <div className="flex justify-between items-center mb-4">
          <Typography
            variant="h5"
            color="#2C3E50"
            style={{ fontFamily: "Montserrat", fontWeight: "600" }}
          >
            Users
          </Typography>
          <div className="flex items-center gap-4">
          <Select
              open={isOpen}
              onClose={() => setIsOpen(false)}
              onOpen={() => setIsOpen(true)}
              value={activationStatus}
              onChange={(e) => setActivationStatus(e.target.value as string)}
              IconComponent={() => (
                <div
                  onClick={handleIconClick}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >
                  <ArrowIcon
                    style={{
                      width: "2rem",
                      height: "2rem",
                      backgroundImage: `url(${ArrowIcon})`,
                      backgroundSize: "cover",
                      color: "#FDA214",
                      transition: "transform 0.3s ease",
                      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                </div>
              )}
              sx={{
                width: 200,
                height: "3rem",
                borderRadius: "10px",
                "& fieldset": {
                  borderRadius: "10px",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214",
                  boxShadow: "0 0 0 2px #FDA214",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FDA214",
                },
              }}
            >
              <MenuItem value="Activate" style={{ fontFamily: "Montserrat" }}>
                Activated
              </MenuItem>
              <MenuItem value="Deactivate" style={{ fontFamily: "Montserrat" }}>
                Blocked
              </MenuItem>
            </Select>
            <div className="relative">
              <SearchIcon
                className="absolute left-4 top-1/2 transform -translate-y-1/2"
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#FDA214",
                  fontFamily: "Montserrat",
                }}
              />
              <Form.Control
                type="text"
                placeholder="Search Users by Name / Email / Phone No."
                className="pl-10 pr-6 py-2 w-112 rounded-full border border-gray-300"
                value={searchQuery}
                onChange={handleSearch}
                style={{
                  fontFamily: "Montserrat",
                  width: "30rem",
                  height: "3.5rem",
                  lineHeight: "3rem",
                  borderRadius: "20px",
                  
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #d3d3d3",
                }} // Custom width and height// Additional rounding if needed
              />
              <style>
                {`
               .relative input::placeholder {
               color: #B4BDC4; // Placeholder color
                   }`}
              </style>
            </div>
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <Typography color="error" className="mb-4">
            {error}
          </Typography>
        )}

        {/* Loading State */}
        {loading ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead
                sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}
              >
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                  >
                    User Name
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                  >
                    Phone no.
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "30px",
                      fontWeight: "bold",
                      py: 1,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Date Joined
                    <IconButton onClick={handleOpenMenu} size="small">
                      <KeyboardArrowDown />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem
                        onClick={() => handleSortSelection("Earliest")}
                        style={{ fontFamily: "Montserrat" }}
                      >
                        Earliest
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleSortSelection("Latest")}
                        style={{ fontFamily: "Montserrat" }}
                      >
                        Latest
                      </MenuItem>
                    </Menu>
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.map((driver) => (
                  <TableRow
                    key={driver.id}
                    onClick={() => {
                      setSelectedUser(driver);
                      setShowModal(true);
                    }}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        hoveredRow === driver.id ? "#f0f0f0" : "transparent",
                    }}
                    onMouseEnter={() => setHoveredRow(driver.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <TableCell
                      sx={{ py: 0.5, fontFamily: "Montserrat" }}
                    >{`${driver.first_name} ${driver.last_name}`}</TableCell>
                    <TableCell
                      sx={{
                        color: "#93310D",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textDecorationColor: "#93310D",
                        fontFamily: "Montserrat",
                        py: 0.5,
                      }}
                    >
                      {driver.phone}
                    </TableCell>
                    <TableCell sx={{ py: 0.5, fontFamily: "Montserrat" }}>
                      {driver.email}
                    </TableCell>
                    <TableCell sx={{ py: 0.5, fontFamily: "Montserrat" }}>
                      {new Date(driver.created_at)
                        .toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                        .replace(/\//g, "-")
                        .toUpperCase()}
                    </TableCell>
                    <TableCell sx={{ py: 0.5, fontFamily: "Montserrat" }}>
                      <Button
                        variant="link"
                        className="text-gray-500 hover:text-gray-700 p-1"
                        onClick={() => {
                          setSelectedUser(driver);
                          setShowModal(true);
                        }}
                      >
                        <StatusIcon
                          className="ml-2"
                          style={{
                            width: "30px",
                            height: "30px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div
          className="d-flex justify-content-center  align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px" }}
        >
          {totalPages > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
        {selectedUser && (
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            size="lg"
            style={{ fontFamily: "Montserrat" }}
          >
            <Box
              sx={{
                top: "50%",
                width: 820, // Adjust as per requirement
                maxHeight: "90vh",
                p: 3,
                borderRadius: 2,
              }}
            >
              <Modal.Header className="border-0 pb-0">
                <Modal.Title
                  className="w-100 text-left font-bold text-xl mb-4 ml-4"
                  style={{ fontFamily: "Montserrat" }}
                >
                  USER DETAILS
                </Modal.Title>
                <IconButton onClick={handleCloseModal} size="small">
                  <CloseIcon sx={{ color: "orange" }} />
                </IconButton>
              </Modal.Header>
              <Modal.Body className="pt-0 ml-4 mr-4">
                <div className="flex items-center mb-4 gap-3">
                  {renderAttachment(selectedUser.profile_image ?? null)}
                  <h2 className="text-2xl font-semibold">
                    {" "}
                    {`${selectedUser.first_name} ${selectedUser.last_name}`}
                  </h2>
                </div>
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-4 relative ">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Phone Number
                        </Form.Label>
                        <PhoneIcon
                          className="absolute left-2 bottom-2.5"
                          style={{
                            width: "29px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="tel"
                          value={selectedUser.phone}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem", // Space for the icon
                            width: "300px", // Reduce width here, adjust as needed
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Vehicle Type
                        </Form.Label>
                        <VehicleIcon
                          className="absolute left-2 bottom-2.5"
                          style={{
                            width: "28px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="text"
                          value={selectedUser.vehicle_type || ""}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          License Plate Number
                        </Form.Label>
                        <LicensePlateIcon
                          className="absolute left-2 bottom-2.5"
                          style={{
                            width: "28px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="text"
                          value={selectedUser.license_plate_number || ""}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Subscription Start Date
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={selectedUser.subscriptionStartDate || ""}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Email
                        </Form.Label>
                        <EmailIcon
                          className="absolute left-2 bottom-2.5"
                          style={{
                            width: "28px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="email"
                          value={selectedUser.email}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Vehicle Color
                        </Form.Label>
                        <ColorIcon
                          className="absolute left-2 bottom-2.5"
                          style={{
                            width: "28px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="text"
                          value={selectedUser.vehicle_color || ""}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Subscription Status
                        </Form.Label>
                        <DollorIcon
                          className="absolute left-2 bottom-2.5"
                          style={{
                            width: "28px",
                            height: "20px",
                            fontFamily: "Montserrat",
                          }}
                        />
                        <Form.Control
                          type="text"
                          value={selectedUser?.account_status || ""}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                            color:
                              selectedUser?.account_status === "Activate"
                                ? "#039828"
                                : selectedUser?.account_status === "Deactivate"
                                ? "#B63F07"
                                : "inherit",
                            fontWeight: "bold",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 relative">
                        <Form.Label
                          style={{
                            marginBottom: "0.0rem",
                            position: "relative",
                            top: "0.2rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Subscription End Date
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={selectedUser.plan_expiry_date || ""}
                          readOnly
                          style={{
                            paddingLeft: "2.5rem",
                            width: "300px",
                            fontFamily: "Montserrat",
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer className="border-0 pt-0 mb-4">
                <div className="w-full flex justify-center px-4">
                  {selectedUser?.account_status === "Deactivate" ? (
                    <RippleButton
                      className="px-7"
                      style={{
                        backgroundColor: "#1FA541",
                        color: "white",
                        outline: "none",
                        border: "none",
                        fontFamily: "Montserrat",
                        padding: "12px 32px", // Increase padding
                        fontSize: "18px", // Increase font size
                        borderRadius: "8px",
                      }}
                      onMouseEnter={(e) => {
                        (e.target as HTMLButtonElement).style.backgroundColor =
                          "#1FA541"; // Darker shade for hover
                      }}
                      onMouseLeave={(e) => {
                        (e.target as HTMLButtonElement).style.backgroundColor =
                          "#1FA541";
                      }}
                      onClick={handleUnBlock} // You'll need to implement this function
                    >
                      Unblock
                    </RippleButton>
                  ) : (
                    <div className="flex justify-center gap-28">
                      <RippleButton
                        className="px-7 ml-28"
                        style={{
                          backgroundColor: "#961F16",
                          color: "white",
                          outline: "none",
                          border: "none",
                          fontFamily: "Montserrat",
                        }}
                        onMouseEnter={(e) => {
                          (
                            e.target as HTMLButtonElement
                          ).style.backgroundColor = "#7a1612";
                        }}
                        onMouseLeave={(e) => {
                          (
                            e.target as HTMLButtonElement
                          ).style.backgroundColor = "#961F16";
                        }}
                        onClick={handleRejectModal}
                      >
                        Remove User
                      </RippleButton>
                      <Button
                        variant="secondary"
                        onClick={handleCloseModal}
                        className="px-14 py-2 mr-36 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        Close
                      </Button>
                    </div>
                  )}
                </div>
              </Modal.Footer>
            </Box>
          </Modal>
        )}
        <Dialog
          open={showRemoveModal}
          onClose={handleCloseRemoveModal}
          aria-labelledby="remove-user-dialog-title"
          PaperProps={{
            style: {
              borderRadius: "14px",
              padding: "5px",
              maxWidth: "630px",
              fontFamily: "Montserrat",
            },
          }}
        >
          {/* Close Button */}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              aria-label="close"
              onClick={handleCloseRemoveModal}
              sx={{
                minWidth: "auto",
                color: "#FDA214",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Modal Title */}
          <DialogTitle
            id="remove-user-dialog-title"
            style={{
              fontWeight: "bold",
              color: "#122E59",
              textAlign: "center",
              paddingBottom: "8px",
              fontFamily: "Montserrat",
            }}
          >
            Remove User
          </DialogTitle>

          {/* Modal Content */}
          <DialogContent>
            <Typography
              style={{
                textAlign: "center",
                color: "#122E59",
                marginBottom: "24px",
                fontFamily: "Montserrat",
              }}
            >
              Are you sure you want to delete this User account?
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "center",
                color: "#122E59",
                marginBottom: "16px",
                fontSize: "18px",
                lineHeight: "1.5",
                fontFamily: "Montserrat",
              }}
            >
              The user will lose all their data and it will not be recoverable.
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "center",
                color: "#122E59",
                marginBottom: "24px",
                fontSize: "18px",
                lineHeight: "1.5",
                fontFamily: "Montserrat",
              }}
            >
              To prevent this user from creating an account again with the same
              email or phone number, press “Delete & Block.”
            </Typography>
          </DialogContent>

          {/* Modal Actions */}
          <DialogActions
            sx={{
              border: "none",
              padding: 4,
              marginTop: "37px",
              justifyContent: "center",
              gap: "16px",
              fontFamily: "Montserrat",
            }}
          >
            {/* Delete & Block Button */}
            <Button
              onClick={handleDeleteAndBlock}
              style={{
                backgroundColor: "transparent",
                border: "1px solid #FDA214",
                color: "#FDA214",
                padding: "12px 24px",

                borderRadius: "24px",
                fontWeight: 500,
                minWidth: "160px",
                height: "48px",
                fontFamily: "Montserrat",
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "rgba(253, 162, 20, 0.1)";
                (e.target as HTMLButtonElement).style.color = "#FDA214";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "transparent";
              }}
            >
              Delete & Block
            </Button>

            {/* Delete Account Button */}
            <Button
              onClick={handleDelete}
              style={{
                backgroundColor: "#FDA214",
                border: "none",
                color: "white",
                padding: "12px 24px",
                borderRadius: "24px",
                fontWeight: 500,
                minWidth: "160px",
                fontFamily: "Montserrat",
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#e89412";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#FDA214";
              }}
            >
              Delete Account
            </Button>

            {/* Cancel Button */}
            <Button
              onClick={handleCloseRemoveModal}
              style={{
                backgroundColor: "transparent",
                border: "1px solid #FDA214",
                color: "#FDA214",
                padding: "12px 24px",
                borderRadius: "24px",
                fontWeight: 500,
                minWidth: "160px",
                fontFamily: "Montserrat",
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "rgba(253, 162, 20, 0.1)";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "transparent";
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};
export default UserComponent;
