import React, { useState } from "react";
import { ReactComponent as CustomLeftArrow } from "../../../../assets/icons/arrow-circle-left.svg";
import { useParams, Link, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "../../PaginationComp/Paginations";

interface PaymentHistory {
  date: string;
  amount: string;
}
const TICKETS_PER_PAGE = 10;

export default function DriverPayoutDetails() {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { name, amountPaid, dateOfPayment } = location.state || {};
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // Calculate the total number of pages//
  const totalpages = Math.ceil(dateOfPayment.length / TICKETS_PER_PAGE);

  const dateOfPaymentArray = Array.isArray(dateOfPayment)
    ? dateOfPayment
    : [{ date: dateOfPayment, amount: amountPaid }]; // Wrap single record in array

  const paginatedTickets: PaymentHistory[] = dateOfPaymentArray.slice(
    (currentPage - 1) * TICKETS_PER_PAGE,
    currentPage * TICKETS_PER_PAGE
  );

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "100%",
        marginLeft: "250px",
        marginTop: "40px",
        fontFamily: "Montserrat",
      }}
    >
      <Box
        sx={{
          p: 3,
          bgcolor: "background.default",
          minHeight: "100vh",
          fontFamily: "Montserrat",
        }}
      >
        <Link
          to="/admin/driver-payouts"
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
            fontFamily: "Montserrat",
          }}
        >
          <CustomLeftArrow
            style={{
              width: 45,
              height: 55,
              marginRight: 10,
              marginBottom: 10,
              fontFamily: "Montserrat",
            }}
          />
          <Typography
            variant="h5"
            gutterBottom
            color="#2C3E50"
            style={{ fontFamily: "Montserrat",  fontWeight: "600" }}
          >
            Driver Payout Details
          </Typography>
        </Link>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 4,
            fontFamily: "Montserrat",
          }}
        >
          <Avatar
            src="/placeholder.svg?height=50&width=50"
            alt={name}
            sx={{ width: 50, height: 50, mr: 2, fontFamily: "Montserrat" }}
          />
          <Typography variant="h5" style={{ fontFamily: "Montserrat" , fontWeight: "600" }}>
            {name}
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ mb: 4, fontFamily: "Montserrat" }}>
          {[
            { label: "No. Of Towing Trips", value: "300" },
            { label: "Total Earned", value: "$ 50,000" },
            { label: "Average Rating", value: "3.5" },
            { label: "Reviews Received", value: "40" },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                sx={{
                  p: 2,
                  textAlign: "left",
                  bgcolor: "#F0F3FA",
                  fontFamily: "Montserrat",
                  boxShadow: "none", 
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="#345C72"
                  gutterBottom
                  style={{ fontFamily: "Montserrat" , fontWeight: 600, fontSize: "16px" }}
                >
                  {item.label}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontFamily: "Montserrat",fontWeight: 700, fontSize: "24px" }}
                  color="#345C72"
                  gutterBottom
                >
                  {item.value}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <TableContainer component={Paper} elevation={0} color="#2C3E50">
          <Table>
            <TableHead
              sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}
            >
              <TableRow color="#2C3E50">
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Date of payment
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Amount Paid
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedTickets.map((ticket: PaymentHistory, index: number) => (
                <TableRow key={index}>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.date}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Montserrat" }}>
                    {ticket.amount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Box>
    </div>
  );
}
